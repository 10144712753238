<template>
  <div class="footer-index-container">
    <div class="footer-index">
      <h1>WHAT OUR BELOVED CUSTOMERS HAVE TO SAY ABOUT US</h1>
      <br>
      <br>
      <Reviews class="hidden-md-and-down" />
      <reviews-responsive class="hidden-lg-and-up" />
      <div class="counter-footer">
        <img
          src="../assets/images/logo-index.svg"
          alt=""
        >
        <div class="footer-social">
          <div
            style="margin-right: 20px; cursor: pointer;"
            @click="goToMail"
          >
            <span>info@countertopmasters.com</span>
          </div>
          <div
            style="margin-right: 20px; cursor: pointer;"
            @click="goToTel"
          >
            <span>929.233.4705</span>
          </div>
          <div>
            <el-button
              type="text"
              icon="icon-facebook2"
              @click="goTo('f')"
            />
            <el-button
              type="text"
              icon="icon-instagram"
              @click="goTo('i')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Reviews from '../components/Reviews.vue'
import ReviewsResponsive from '../components/ReviewsResponsive.vue'
/**
 *
 * Footer Component
 *
 * @author cessarm_
 * @copyright 2022, Eco Development Studios
 */
export default {
  name: 'FooterItem',
  components: {
    Reviews,
    ReviewsResponsive
  },
  methods: {
    goToMail () {
      window.location.href = 'mailto:info@countertopmasters.com'
    },
    goToTel () {
      window.location.href = 'tel:9292334705 '
    },
    goTo (link) {
      if (link === 'f') {
        window.open('https://www.facebook.com/CounterTopMastersNY/', '_blank')
      }
      if (link === 'i') {
        window.open('https://www.instagram.com/countertopmasters/', '_blank')
      }
    }
  }
}
</script>

<style lang="scss">
.footer-index-container {
  background-color: #2f1c15;

  @media screen and (max-width: 1280px) {
    padding: 0 60px;
  }

  @media screen and (max-width: 414px) {
    padding: 0 20px;
  }
}

.footer-index {
  height: auto;
  padding-top: 30px;
  width: 1280px;
  margin: 0 auto;

  @media screen and (max-width: 1280px) {
    width: 100%;
  }

  h1 {
    font-family: "Raleway", sans-serif;
    font-weight: 100;
    color: #fff;
    margin: 0;

    @media screen and (max-width: 414px) {
      font-size: 1.6rem;
    }
  }

  .counter-footer {
    bottom: 0;
    width: 100%;
    margin-top: 30px;

    img {
      width: 160px;
      margin-bottom: 10px;
    }

    .footer-social {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      text-align: center;

      @media screen and (max-width: 414px) {
        flex-direction: column;

        i {
          font-size: 1.4rem;
        }
      }

      i {
        color: white;
      }
    }
  }
}
</style>
