<template>
  <div id="app">
    <TheHeader />
    <router-view />
    <MenuFixed />
  </div>
</template>

<script>
import TheHeader from './components/Header.vue'
import MenuFixed from './components/MenuFixed.vue'
export default {
  components: {
    TheHeader,
    MenuFixed
  },
}
</script>

<style lang="scss">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
}

body,
html {
  padding: 0;
  margin: 0;
  overflow-x: hidden !important;

  .v-modal {
    opacity: 0.9 !important;
  }

  .el-loading-mask {
    z-index: 1999;
  }

  .el-notification,
  .el-dropdown-menu__item {
    font-family: 'Raleway', sans-serif;
  }
}
</style>
