/* eslint-disable */
<template>
  <div class="FooterHome">
    <div
      v-for="(service, index) in services"
      :key="index"
      class="service-container"
      @click="goTo(service.route)"
    >
      <div class="service-content">
        <div class="service-title">
          <h2>{{ service.title }}</h2>
          <p class="service-text">
            {{ service.text }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
*
* FooterHome Component
*
* @author cessarm_
* @copyright 2022, Eco Development Studios
*/
export default {
  name: 'FooterHome',
  data () {
    return {
      services: [
        {
          title: 'DESIGN & CREATE',
          text: 'Whether you want a gorgeous kitchen/bathroom Countertop or customized stone island support, our experts are here to help.',
          route: 'design'
        },
        {
          title: 'REPAIRING & MAINTAINING',
          text: 'From repairing raptured countertop surface to fixing cracks on the edge of fixture or appliance supported by the countertop, we got you covered!',
          route: 'repairing'
        },
        {
          title: 'REPLACE & REFACE',
          text: 'If our stone experts determine that your countertop reached its end and there is no possible ways to repair, we bring back its original look by completely replacing it.',
          route: 'redesign'
        }
      ]
    }
  },
  methods: {
    goTo (route) {
      this.$router.push({ name: route })
    }
  }
}
</script>

<style lang='scss'>
.FooterHome {
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  z-index: 2;

  .service-container {
    width: calc(100% / 3);
    height: 150px;
    position: relative;
    background-color: rgba(black, .3);
    cursor: pointer;

    @media screen and (max-width: 768px) {
      height: 250px;
    }

    &:nth-child(2) {
      background-color: rgba(white, .1);
    }
  }

  .service-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    width: 100%;
    text-align: center;
    font-family: 'Raleway', sans-serif;

    h1 {
      font-weight: 100;
      opacity: .2;
      font-size: 72px;
      margin: 0;
      margin-top: 10px;
    }

    .service-title {
      width: 100%;

      h2 {
        font-weight: 400;
        font-size: 1rem;
      }
    }

    .service-text {
      padding: 0 70px;
      margin: 0px;
      font-size: .9rem;
      font-weight: 100;

      @media screen and (max-width: 768px) {
        padding: 0 10px;
      }
    }
  }
}
</style>
