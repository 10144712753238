<template>
  <div class="Reviews">
    <el-row>
      <el-col :span="12">
        <div class="quotes-container">
          <div class="quotes">
            <i class="icon-quotes-left" />
          </div>
        </div>
        <el-row
          type="flex"
          align="middle"
        >
          <el-col :span="4">
            <el-button
              icon="icon-chevron-left"
              class="arrow"
              @click="NextAndPrevSlide"
            />
          </el-col>
          <el-col
            v-if="firstReview"
            :span="20"
            class="column-customer"
          >
            <p class="customer-comment">
              {{ reviews[0].text }}
            </p>
            <div class="customer-name">
              <div class="square" />
              <div>
                {{ reviews[0].name }}
              </div>
            </div>
          </el-col>
          <el-col
            v-if="secondReview"
            :span="20"
            class="column-customer"
          >
            <p class="customer-comment">
              {{ reviews[2].text }}
            </p>
            <div class="customer-name">
              <div class="square" />
              <div>
                {{ reviews[2].name }}
              </div>
            </div>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="12">
        <div class="quotes-container">
          <div class="quotes">
            <i class="icon-quotes-right" />
          </div>
        </div>
        <el-row
          type="flex"
          align="middle"
        >
          <el-col
            v-if="secondReview"
            :span="20"
            class="column-customer"
          >
            <p class="customer-comment">
              {{ reviews[1].text }}
            </p>
            <div class="customer-name">
              <div class="square" />
              <div>
                {{ reviews[1].name }}
              </div>
            </div>
          </el-col>
          <el-col
            v-if="firstReview"
            :span="20"
            class="column-customer"
          >
            <p class="customer-comment">
              {{ reviews[3].text }}
            </p>
            <div class="customer-name">
              <div class="square" />
              <div>
                {{ reviews[3].name }}
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <el-button
              icon="icon-chevron-right"
              class="arrow"
              @click="NextAndPrevSlide"
            />
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
/**
*
* Reviews Component
*
* @author cessarm_
* @copyright 2022, Eco Development Studios
*/
export default {
  name: 'ReviewsItem',
  data () {
    return {
      firstReview: true,
      secondReview: false,
      reviews: [
        {
          page: 1,
          text: 'Papuna is indeed a master when it comes to handling the \n' +
            'kitchen countertops. He was able to quickly grasp the nature \n' +
            'of the project and all the details that were involved in \n' +
            'fabricating and installing the countertop to our needs \n' +
            'He was very professional and always responsive throught \n' +
            'the process, but best of all, his quoted price was the lowes \n' +
            'one received from the other vendors. Overall, we are ver \n' +
            'satisfied, and we highly recommend CounterTop Masters',
          name: 'Denny Bucci'
        },
        {
          page: 1,
          text: 'Nick and Papuna were very professional, straight forward and punctual.\n' +
            'They made the whole experience of designing our countertop very\n' +
            'easy and pleasant. I would very much recommend their services.',
          name: 'Deirdre Fitzgerald'
        },
        {
          page: 2,
          text: 'I was advised to replace my granite countertop since the damage was \n' +
            'beyond repair. CounterTop Masters was one of the several companies I contacted \n' +
            'for the estimate. Not only the team showed up on time, but after thorough \n' +
            'examination of all the damages, they advices me there was no need for \n' +
            'replacing it and they indeed brought it back to the original look. \n' +
            'These guys are true artist who saved me over 5k. Keep up the good work!',
          name: 'Graham Norris'
        }, {
          page: 2,
          text: 'Even though I was in love with my old wooden countertop, it was just not \n' +
            'practical at all. Having Nick and Papuna customizing a brand new white (stone) \n' +
            'countertop for my tiny kitchen, made it look so much bigger and neater. \n' +
            'Not only it looks beautiful, but it’s also very easy to clean. Excellent \n' +
            'advice and super professional attention from Countertop Masters, thank you so much!',
          name: 'Diana Davalos'
        }
      ]
    }
  },
  methods: {
    NextAndPrevSlide () {
      this.firstReview = !this.firstReview
      this.secondReview = !this.secondReview
    }
  }
}
</script>

<style lang='scss'>
.Reviews {
  .quotes-container {
    text-align: center;
    height: 60px;
    position: relative;

    .quotes {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: #d2c7a5;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;

      i {
        font-size: 2.1rem;
        color: #fff;
        line-height: 60px;
      }
    }
  }

  .arrow {
    background-color: transparent;
    border-radius: 0;
    color: #fff;
    font-size: 1.3rem;
  }

  .customer-comment {
    color: #fff;
    padding: 0 20px;
    text-align: justify;
  }

  .column-customer {
    position: relative;
    height: 300px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  .customer-name {
    text-align: center;
    width: 50%;
    color: #fff;
    margin: 0 auto;
    display: flex;
    position: absolute;
    bottom: 0;
    padding-left: 30px;

    .square {
      width: 20px;
      height: 20px;
      background-color: #d2c7a5;
      margin-right: 10px;
    }
  }
}
</style>
