/* eslint-disable */
<template>
  <div class="ReviewsResponsive">
    <div
      class="btn-direction btn-left"
      @click="setDirection('prev')"
    >
      <el-button
        type="text"
        icon="icon-chevron-left"
      />
    </div>
    <div
      v-show="first"
      class="review-container"
    >
      <p>
        Papuna is indeed a master when it comes to handling the kitchen countertops.
        He was able to quickly grasp the nature of the project and all the details
        that were involved in fabricating and installing the countertop to our needs.
        He was very professional and always responsive throughout the process, but best
        of all, his quoted price was thelowest one we received from the other vendors.
        Overall, we are very satisfied, and we highly recommend CounterTop Masters!
      </p>
      <div class="name-review">
        <div class="square" />
        <div class="name">
          <span>
            Denny Bucci
          </span>
          <small />
        </div>
      </div>
    </div>
    <div
      v-show="second"
      class="review-container"
    >
      <p>
        Nick and Papuna very professional, straight forward and punctual.
        They made the whole experience of designing our countertop very
        easy and pleasant. I would very much recommend their services
      </p>
      <div class="name-review">
        <div class="square" />
        <div class="name">
          <span>
            Deirdre Fitzgerald
          </span>
          <small />
        </div>
      </div>
    </div>
    <div
      v-show="third"
      class="review-container"
    >
      <p>
        I was advised to replace my granite countertop since
        the damage was beyond repair. CounterTop was one of
        the seral companies I contacted fortheestimate. Not only
        the team showed up on time, but after thorough examinationof
        all the damages, they advices me there was no need for replacing
        it and they indeed brought it back to the original look.
        These guys are true artist who save me over 5k. Keep up the
        good work!
      </p>
      <div class="name-review">
        <div class="square" />
        <div class="name">
          <span>
            Graham Norris
          </span>
          <small />
        </div>
      </div>
    </div>
    <div
      v-show="fourth"
      class="review-container"
    >
      <p>
        Even though I was in love with my old wooden countertop,
        it was just not practical at all. Having Nick and Papuna
        customizing a brand new white (stone) countertop for my
        tiny kitchen, made it look so much bigger and neater.
        Not only it looks beautiful, but it’s also very easy to clean.
        Excellent advice and super professional attention from
        Countertop Masters, thank you so much!
      </p>
      <div class="name-review">
        <div class="square" />
        <div class="name">
          <span>
            Diana Davalos
          </span>
          <small />
        </div>
      </div>
    </div>
    <div
      class="btn-direction btn-right"
      @click="setDirection('next')"
    >
      <el-button
        type="text"
        icon="icon-chevron-right"
      />
    </div>
  </div>
</template>

<script>
/**
*
* ReviewsResponsive Component
*
* @author cessarm_
* @copyright 2022, Eco Development Studios
*/
export default {
  data () {
    return {
      first: true,
      second: false,
      third: false,
      fourth: false
    }
  },
  methods: {
    setDirection (direction) {
      if (direction === 'prev') {
        if (this.first) {
          this.first = false
          this.fourth = true
        } else if (this.fourth === true) {
          this.fourth = false
          this.third = true
        } else if (this.third === true) {
          this.third = false
          this.second = true
        } else if (this.second === true) {
          this.second = false
          this.first = true
        }
      }
      if (direction === 'next') {
        if (this.first) {
          this.first = false
          this.second = true
        } else if (this.second === true) {
          this.second = false
          this.third = true
        } else if (this.third === true) {
          this.third = false
          this.fourth = true
        } else if (this.fourth === true) {
          this.fourth = false
          this.first = true
        }
      }
    }
  }
}
</script>

<style lang='scss'>
.ReviewsResponsive {
  position: relative;
  display: flex;
  align-items: center;
  height: 200px;

  @media screen and (max-width: 768px) {
    height: auto;
  }

  .btn-direction {
    @media screen and (max-width: 414px) {
      top: 40%;
    }

    i {
      font-size: 4rem;
      color: white;

      @media screen and (max-width: 414px) {
        font-size: 2rem;
      }
    }
  }

  .btn-left {
    left: 5%;

    @media screen and (max-width: 414px) {
      left: 3%;
    }
  }

  .btn-right {
    right: 5%;

    @media screen and (max-width: 414px) {
      right: 3%;
    }
  }

  .review-container {
    width: 90%;
    margin: 0 auto;
    font-family: 'Raleway', sans-serif;
    font-size: 1rem;
    text-align: justify;
    position: relative;

    p {
      color: white;
      line-height: 1.5em
    }

    .name-review {
      display: flex;
      bottom: 0;
      width: 100%;
      align-items: center;

      .square {
        width: 45px;
        height: 40px;
        background-color: #d2c7a5;
      }

      .name {
        padding-left: 10px;
        width: 100%;

        span {
          color: white;
          font-weight: 500;
        }
      }
    }
  }
}
</style>
