<template>
  <div class="Schedule">
    <div class="title-container">
      <h1 class="title-schedule">
        SCHEDULE A FREE CONSULATION
      </h1>
    </div>
    <br>
    <el-row>
      <el-col
        :xs="24"
        :sm="24"
        :md="12"
        :lg="12"
        :xl="12"
        class="text-schedule"
      >
        <p>
          Please fill out Consultation form so we can assist you in a best way
          possible. Please note that all fields are required for successful
          delivery of your message.
        </p>
      </el-col>
      <el-col
        v-loading="loading"
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
        class="form-container"
      >
        <p class="title-form">
          SCHEDULE A FREE CONSULATION
        </p>
        <el-form
          ref="scheduleForm"
          action=""
          :model="scheduleFormModel"
          :rules="scheduleFormRules"
        >
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item prop="name">
                <el-input
                  id="name"
                  v-model="scheduleFormModel.name"
                  type="text"
                  placeholder="Name"
                  name="name"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="lastName">
                <el-input
                  id="lastName"
                  v-model="scheduleFormModel.lastName"
                  type="text"
                  placeholder="Last name"
                  name="lastName"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item prop="phoneNumber">
                <el-input
                  id="phoneNumber"
                  v-model="scheduleFormModel.phoneNumber"
                  type="text"
                  placeholder="Phone number"
                  name="phoneNumber"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item prop="email">
                <el-input
                  id="email"
                  v-model="scheduleFormModel.email"
                  type="text"
                  placeholder="Email"
                  name="email"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="8">
              <el-form-item prop="city">
                <el-input
                  id="city"
                  v-model="scheduleFormModel.city"
                  type="text"
                  placeholder="City"
                  name="city"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="state">
                <el-select
                  id="state"
                  v-model="scheduleFormModel.state"
                  placeholder="State"
                  name="state"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="zipCode">
                <el-input
                  id="zipCode"
                  v-model="scheduleFormModel.zipCode"
                  type="text"
                  placeholder="Zip code"
                  name="zipCode"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-form-item prop="message">
              <el-input
                id="message"
                v-model="scheduleFormModel.message"
                type="textarea"
                placeholder="Message"
                name="message"
              />
            </el-form-item>
          </el-row>
          <el-button
            class="btn-submit"
            :disabled="buttonDisabled"
            @click="submitForm('scheduleForm')"
          >
            Submit
          </el-button>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import axios from 'axios'
/**
 *
 * Schedule Component
 *
 * @author cessarm_
 * @copyright 2022, Eco Development Studios
 */
export default {
  name: 'ScheduleItem',
  data () {
    return {
      buttonDisabled: false,
      loading: false,
      options: [
        {
          value: 'NY',
          label: 'NY'
        },
        {
          value: 'NJ',
          label: 'NJ'
        }
      ],
      value: '',
      scheduleFormModel: {
        name: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        city: '',
        state: '',
        zipCode: '',
        message: ''
      },
      scheduleFormRules: {
        name: [
          { required: true, message: 'Please fill this field', trigger: 'blur' }
        ],
        lastName: [
          { required: true, message: 'Please fill this field', trigger: 'blur' }
        ],
        phoneNumber: [
          { required: true, message: 'Please fill this field', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'Please fill this field', trigger: 'blur' }
        ],
        city: [
          { required: true, message: 'Please fill this field', trigger: 'blur' }
        ],
        state: [
          { required: true, message: 'Please fill this field', trigger: 'blur' }
        ],
        zipCode: [
          { required: true, message: 'Please fill this field', trigger: 'blur' }
        ],
        message: [
          { required: true, message: 'Please fill this field', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.sendData()
        } else {
          return false
        }
      })
    },
    sendData () {
      this.buttonDisabled = true
      this.loading = true
      document.cookie =
        'userInfo' + '=' + JSON.stringify(this.scheduleFormModel)
      var formData = new FormData()

      formData.append('name', this.scheduleFormModel.name)
      formData.append('lastName', this.scheduleFormModel.lastName)
      formData.append('phoneNumber', this.scheduleFormModel.phoneNumber)
      formData.append('email', this.scheduleFormModel.email)
      formData.append('city', this.scheduleFormModel.city)
      formData.append('state', this.scheduleFormModel.state)
      formData.append('zipCode', this.scheduleFormModel.zipCode)
      formData.append('message', this.scheduleFormModel.message)

      axios({
        method: 'POST',
        url: '/sendEmail.php',
        data: formData,
        dataType: 'json',
        processData: false,
        contentType: false
      }).then(response => {
        if (response.data === 'OK') {
          this.loading = false
          this.buttonDisabled = false
          this.$message({
            title: 'Success',
            message: 'Thank you for contacting CounterTop Masters!',
            type: 'success',
            duration: 1000
          })
          this.$refs['scheduleForm'].resetFields()
        }
      })
    }
  }
}
</script>

<style lang="scss">
.Schedule {
  margin-top: 40px;
  margin-bottom: 40px;

  .el-textarea__inner {
    border: 1px solid #424345;
  }

  .el-input__inner {
    border: 1px solid #424345;
  }

  @media screen and (max-width: 414px) {
    margin-top: 0;
  }

  .title-container {
    text-align: left;

    @media screen and (max-width: 414px) {
      text-align: left;
    }

    .title-schedule {
      font-family: "Raleway", sans-serif;
      font-weight: 600;
      letter-spacing: 3px;
      font-size: 2rem;
      margin: 0;
    }
  }

  .text-schedule {
    text-align: justify;
  }

  .btn-submit {
    border-radius: 0;
    border-color: #000;
    background-color: #000;
    color: #fff;
    margin-top: 20px;
    width: 100%;

    &:hover {
      background-color: #000;
      color: #fff;
    }

    &:active {
      background-color: rgb(11, 11, 11);
      color: #fff;
    }
  }

  .form-container {
    text-align: left;
    padding-left: 20px;

    @media screen and (max-width: 1024px) {
      padding: 0;
    }

    .title-form {
      font-weight: 600;
    }
  }

  .general-input {
    width: 100%;
    margin-bottom: 10px;
    height: 30px;
    -webkit-appearance: none;
    border-radius: 4px;
    border: 1px solid rgba(215, 215, 215, 0.8);

    &::-webkit-input-placeholder {
      color: rgba(167, 167, 167, 0.968);
      font-family: "Raleway";
      margin-left: 10px;
    }

    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: rgba(167, 167, 167, 0.968);
      font-family: "Raleway";
      margin-left: 10px;
    }

    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: rgba(167, 167, 167, 0.968);
      font-family: "Raleway";
      margin-left: 10px;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: rgba(167, 167, 167, 0.968);
      font-family: "Raleway";
      margin-left: 10px;
    }
  }

  textarea {
    &::-webkit-input-placeholder {
      color: rgba(167, 167, 167, 0.968);
      font-family: "Raleway";
      margin-left: 10px;
    }

    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: rgba(167, 167, 167, 0.968);
      font-family: "Raleway";
      margin-left: 10px;
    }

    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: rgba(167, 167, 167, 0.968);
      font-family: "Raleway";
      margin-left: 10px;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: rgba(167, 167, 167, 0.968);
      font-family: "Raleway";
      margin-left: 10px;
    }
  }
}
</style>
