<template>
  <div class="slide-home">
    <el-carousel
      height="100vh"
      :interval="5000"
      autoplay
      indicator-position="none"
    >
      <el-carousel-item
        v-for="(img, index) in images"
        :key="index"
      >
        <div
          :style="{backgroundImage: 'url(' + require('../assets/images/'+ img.name )}"
          class="img-home"
        />
      </el-carousel-item>
    </el-carousel>
    <slot />
  </div>
</template>

<script>
/**
*
* SlideHome Component
*
* @author cessarm_
* @copyright 2022, Eco Development Studios
*/
export default {
  data () {
    return {
      images: [
        {
          name: 'home-01.webp'
        },
        {
          name: 'home-02.webp'
        },
        {
          name: 'home-03.webp'
        },
        {
          name: 'home-04.webp'
        },
        {
          name: 'home-05.webp'
        }
      ]
    }
  }
}
</script>

<style lang='scss'>
.slide-home {
  position: relative;

  .img-home {
    width: 100vw;
    height: 100vh;
    background-position: center center;
    background-size: cover;

    @media screen and (max-width: 768px) {
      filter: grayscale(30%);
      background-position: center center;
    }
  }
}
</style>
