/* eslint-disable */
<template>
  <el-menu
    default-active="2"
    class="menu-fixed hidden-sm-and-down"
    :collapse="isCollapse"
  >
    <el-menu-item
      index="1"
      @click="goTo('h')"
    >
      <i class="icon-home" />
      <span slot="title">Home</span>
    </el-menu-item>
    <el-submenu index="2">
      <template slot="title">
        <i class="icon-layers" />
      </template>
      <el-menu-item-group>
        <el-menu-item
          index="1-1"
          @click="goTo('d')"
        >
          Design & Create
        </el-menu-item>
      </el-menu-item-group>
      <el-menu-item-group>
        <el-menu-item
          index="1-2"
          @click="goTo('r')"
        >
          Repairing & Maintaining
        </el-menu-item>
      </el-menu-item-group>
      <el-menu-item-group>
        <el-menu-item
          index="1-3"
          @click="goTo('rd')"
        >
          Replace & Reface
        </el-menu-item>
      </el-menu-item-group>
    </el-submenu>
    <el-menu-item
      index="4"
      @click="goTo('contact')"
    >
      <i class="icon-mail" />
      <span slot="title">
        Contact
      </span>
    </el-menu-item>
  </el-menu>
</template>

<script>
/**
*
* MenuFixed Component
*
* @author cessarm_
* @copyright 2022, Eco Development Studios
*/
export default {
  data () {
    return {
      isCollapse: true
    }
  },
  methods: {
    goTo (link) {
      if (link === 'd') {
        this.$router.push({ name: 'design' })
      }
      if (link === 'r') {
        this.$router.push({ name: 'repairing' })
      }
      if (link === 'rd') {
        this.$router.push({ name: 'redesign' })
      }
      if (link === 'h') {
        this.$router.push({ name: 'home' })
      }
      if (link === 'contact') {
        this.$router.push({ name: 'contact' })
      }
    }
  }
}
</script>

<style lang='scss'>
.menu-fixed {
  position: fixed !important;
  z-index: 2001;
  top: 120px;
  right: 0;
}

.el-menu--collapse {
  width: 50px !important;
}

.el-menu {
  border-right: solid 1px #e6e6e6;
  list-style: none;
  margin: 0;
  padding-left: 0;
  background-color: transparent !important;

  li {
    background-color: #ff6e01 !important;
    margin-bottom: 5px;
  }
}

.el-menu-item,
.el-submenu__title {
  height: 50px !important;
  line-height: 50px !important;
}

.el-menu-item {
  color: #fff !important;
  font-family: 'Raleway', sans-serif;

  i {
    color: #fff !important;
  }
}

.el-submenu__title i {
  color: #fff !important;
}
</style>
