/* eslint-disable */
<template>
  <div class="MenuResponsive">
    <div class="close-container">
      <el-button
        icon="el-icon-close"
        type="text"
        @click="close"
      />
    </div>
  </div>
</template>

<script>
/**
*
* MenuResponsive Component
*
* @author cessarm_
* @copyright 2022, Eco Development Studios
*/
export default {
  name: 'MenuResponsive',
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang='scss'>
.MenuResponsive {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 99999999999;

  .close-container {
    text-align: right;
    border: 1px solid red;
    padding: 10px;

    i {
      font-size: 1.2rem;
      color: #444;
    }
  }
}
</style>
