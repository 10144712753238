<template>
  <header class="layout-header">
    <transition name="slide-fade">
      <menu-responsive
        v-show="openResponsiveMenu"
        @close="closeMenu"
      />
    </transition>
    <el-row
      class="header-page"
      type="flex"
      align="middle"
    >
      <el-col
        :xs="12"
        :sm="12"
        :md="9"
        :lg="13"
        :xl="13"
        class="img-container"
      >
        <img
          class="logo"
          src="../assets/images/ctm-logo.svg"
          alt=""
          @click="goTo('home')"
        >
      </el-col>
      <el-col
        :xs="12"
        :sm="12"
        :md="15"
        :lg="11"
        :xl="11"
        class="info-basic hidden-sm-and-down"
      >
        <div class="container">
          <span
            class="email"
            @click="goToMail"
          >
            info@countertopmasters.com
          </span>
          <el-divider direction="vertical" />
          <span
            class="number"
            @click="goToTel"
          >
            929.233.4705
          </span>
        </div>
        <div class="container-social">
          <el-button
            type="text"
            icon="icon-facebook2"
            @click="goTo('f')"
          />
          <el-button
            type="text"
            icon="icon-instagram"
            @click="goTo('i')"
          />
        </div>
        <div class="container-contact">
          <el-button
            class="btn-contact"
            @click="goTo('contact')"
          >
            CONTACT US
          </el-button>
        </div>
      </el-col>
      <el-col
        :xs="12"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="12"
        class="hidden-md-and-up menu-container"
      >
        <el-dropdown
          trigger="click"
          :hide-on-click="false"
        >
          <span class="el-dropdown-link">
            <i
              class="icon-menu"
              style="font-size: 1.6rem; color: white;"
            />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <el-button
                type="text"
                @click="goTo('h')"
              >
                Home page
              </el-button>
            </el-dropdown-item>
            <el-dropdown-item>
              <el-dropdown
                trigger="click"
                :hide-on-click="true"
              >
                <span class="el-dropdown-link">
                  Services
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <el-button
                      type="text"
                      @click="goTo('d')"
                    >
                      Design and Create
                    </el-button>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button
                      type="text"
                      @click="goTo('r')"
                    >
                      Repairing and Maitaining
                    </el-button>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button
                      type="text"
                      @click="goTo('rd')"
                    >
                      Replace and Reface
                    </el-button>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-dropdown-item>
            <el-dropdown-item>
              <el-button
                type="text"
                @click="goTo('contact')"
              >
                Contact Us
              </el-button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-row>
  </header>
</template>

<script>
import MenuResponsive from '../components/MenuResponsive.vue'
/**
 *
 * Header Component
 *
 * @author cessarm_
 * @copyright 2022, Eco Development Studios
 */
export default {
  name: 'HeaderItem',
  components: {
    MenuResponsive
  },
  data () {
    return {
      openResponsiveMenu: false
    }
  },
  methods: {
    closeMenu () {
      this.openResponsiveMenu = false
    },
    openMenu () {
      this.openResponsiveMenu = true
    },
    goToMail () {
      window.location.href = 'mailto:info@countertopmasters.com'
    },
    goToTel () {
      window.location.href = 'tel:9292334705 '
    },
    goTo (link) {
      if (link === 'd') {
        this.$router.push({ name: 'design' })
      }
      if (link === 'r') {
        this.$router.push({ name: 'repairing' })
      }
      if (link === 'rd') {
        this.$router.push({ name: 'redesign' })
      }
      if (link === 'h') {
        this.$router.push({ name: 'home' })
      }
      if (link === 'contact') {
        this.$router.push({ name: 'contact' })
      }
      if (link === 'home') {
        this.$router.push({ name: link })
      }
      if (link === 'contact') {
        this.$router.push({ name: link })
      }
      if (link === 'f') {
        window.open('https://www.facebook.com/CounterTopMastersNY/', '_blank')
      }
      if (link === 'i') {
        window.open('https://www.instagram.com/countertopmasters/', '_blank')
      }
    }
  }
}
</script>

<style lang="scss">
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateX(10px);
  opacity: 0;
}

.layout-header {
  background-color: #9eada3;
  position: fixed;
  width: 100%;
  z-index: 2000;
  margin: 0 auto;
  padding: 5px 0;
  -webkit-box-shadow: 0px 10px 8px -5px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0px 10px 8px -5px rgba(0, 0, 0, 0.19);
  box-shadow: 0px 10px 8px -5px rgba(0, 0, 0, 0.19);

  @media screen and (max-width: 1280px) {
    width: 100%;
  }
}

.header-page {
  width: 1280px;
  margin: 0 auto;
  height: 60px;

  @media screen and (max-width: 1280px) {
    width: 100%;
    padding: 0 60px;
    height: 40px;
  }

  @media screen and (max-width: 414px) {
    padding: 0 10px;
  }

  .img-container {
    text-align: left;

    .logo {
      width: 210px;
      margin-top: 10px;
      cursor: pointer;

      @media screen and (max-width: 414px) {
        width: 180px;
      }
    }
  }

  .menu-container {
    text-align: right;

    .btn-menu {
      color: white;
      border-radius: 0;
      font-size: 1.5rem;
    }
  }

  .info-basic {
    color: #fff;
    font-family: "Raleway", sans-serif;
    display: flex;

    @media screen and (max-width: 768px) {
      padding-right: 20px;
    }

    .container {
      display: flex;
      width: 100%;
      align-items: center;

      span {
        cursor: pointer !important;
        font-size: 1rem;
      }

      @media screen and (max-width: 768px) {

        .email,
        .number {
          font-size: 0.8rem;
        }
      }
    }

    .container-social {
      display: flex;
      justify-content: space-around;
      width: 25%;
      margin-left: 15px;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      i {
        color: #fff;
        font-size: 1.1rem;

        &:hover {
          transition: all 0.3s ease-in-out;
          font-size: 1.16rem;
        }
      }
    }
  }

  .container-contact {
    display: flex;
    align-items: center;
    padding-left: 10px;

    .btn-contact {
      background-color: transparent;
      border-radius: 0;
      padding: 0px 30px;
      font-size: 0.6rem;
      height: 30px;
      color: #fff;
      border-color: white;

      &:hover {
        background-color: white;
        color: black;
      }
    }
  }
}
</style>
