<template>
  <div class="AboutUs">
    <div class="about-container">
      <div class="img-about" />
      <div class="text-about">
        <h1>ABOUT US</h1>
        <p>
          Countertop Masters offers <b>everything you need to accomplish your
            goals</b> regarding your countertop project. With over 10 gears of
          experience, we have mastered in designing and creating new
          countertops as well as repairing, replacing and refacing the
          countertop you have in place. <b>Countertop Masters have become trusted in
            community and have had amazing customer feedback.</b> If you are
          looking to modernize your home with beautifully crafted countertops or
          giving a brand new look to your existing countertop, <b>look no
            further than Countertop Masters!</b>
        </p>
        <el-button
          class="btn-about"
          @click="goToContact"
        >
          CONTACT US
        </el-button>
      </div>
      <img
        src="../assets/images/logo-color.svg"
        alt=""
        class="logo-about"
      >
    </div>
  </div>
</template>

<script>
/**
*
* AboutUs Component
*
* @author cessarm_
* @copyright 2022, Eco Development Studios
*/
export default {
  name: 'AboutUs',
  methods: {
    goToContact () {
      this.$router.push({ name: 'contact' })
    }
  }
}
</script>

<style lang='scss'>
.AboutUs {
  padding: 60px 0;
  background-color: rgba(0, 0, 0, .05);

  @media screen and (max-width: 1280px) {
    padding: 60px 60px;
  }

  @media screen and (max-width: 414px) {
    padding: 20px 20px;
  }

  .about-container {
    width: 1280px;
    margin: 0 auto;
    display: flex;
    position: relative;

    @media screen and (max-width: 1280px) {
      width: 100%;
    }

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }

    .img-about {
      background-image: url('../assets/images/about-us.webp');
      width: 40%;
      background-size: cover;

      @media screen and (max-width: 1024px) {
        width: 100%;
        height: 400px;
        background-position: center -100px;
        background-repeat: no-repeat;
      }

      @media screen and (max-width: 414px) {
        height: 200px;
        background-position: center 0;
      }
    }

    .text-about {
      width: 60%;
      padding-left: 60px;
      text-align: left;

      @media screen and (max-width: 1024px) {
        text-align: center;
        margin-top: 30px;
        width: 100%;
        padding: 0;
      }

      @media screen and (max-width: 414px) {
        padding-left: 0;
      }

      h1 {
        font-family: 'Raleway', sans-serif;
        margin-top: 0;
        font-weight: 100;
      }

      p {
        font-family: 'Quicksand', sans-serif;
        text-align: justify;

        @media screen and (max-width: 1024px) {
          text-align: justify;
        }

        @media screen and (max-width: 414px) {
          padding: 0 10px;
          ;
        }
      }
    }

    .btn-about {
      border-radius: 0;
      border-color: #000;
      color: #000;
      background-color: transparent;
    }
  }

  .logo-about {
    position: absolute;
    width: 250px;
    opacity: 0.2;
    right: 0;
    top: 0;
    bottom: 0;

    @media screen and (max-width: 1024px) {
      top: 400px;
      right: 0;
    }

    @media screen and (max-width: 414px) {
      opacity: 0.1;
      top: 350px;
    }
  }
}
</style>
