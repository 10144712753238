<template>
  <div class="service-we-offer-container">
    <div class="service-we-offer">
      <h1 class="we-offer-title">
        SERVICES WE OFFER
      </h1>
      <el-row
        class="service-container"
        type="flex"
        justify="space-between"
      >
        <el-col
          v-for="(service, index) in services"
          :key="index"
          :xs="24"
          :sm="24"
          :md="5"
          :lg="5"
          :xl="5"
          class="service"
          :style="{backgroundColor: service.color}"
        >
          <p>
            {{ service.title }}
          </p>
          <img
            src="../assets/images/cuadro.svg"
            alt=""
            :style="{transform: 'rotate('+service.rotate+')', backgroundColor: ''}"
          >
        </el-col>
      </el-row>
      <Schedule />
    </div>
  </div>
</template>

<script>
import Schedule from '../components/Schedule.vue'
/**
*
* ServiceWeOffer Component
*
* @author cessarm_
* @copyright 2022, Eco Development Studios
*/
export default {
  components: {
    Schedule
  },
  data () {
    return {
      services: [
        {
          title: 'COMMERCIAL & RESIDENTIAL COUNTERTOP SERVICES',
          rotate: '0',
          color: '#9eada3'
        },
        {
          title: 'COUNTERTOP/CUSTOM COUNTERTOP FABRICATION',
          rotate: '90deg',
          color: '#ab9a89'
        },
        {
          title: 'REFINISHING KITCHEN & BATHROOM COUNTERTOP',
          rotate: '180deg',
          color: '#d3c8a4'
        },
        {
          title: 'REPAIR SERVICES ON CORAIN, GRANITE AND QUARTZ COUNTERTOP',
          rotate: '-450deg',
          color: '#8d6e5f'
        }
      ]
    }
  }
}
</script>

<style lang='scss'>
.service-we-offer-container {
  @media screen and (max-width: 1280px) {
    padding: 0 60px;
  }

  @media screen and (max-width: 414px) {
    padding: 0 20px;
  }
}

.service-we-offer {
  width: 1280px;
  margin: 0 auto;

  @media screen and (max-width: 1280px) {
    width: 100%;
  }

  .we-offer-title {
    font-family: 'Raleway', sans-serif;
    font-weight: 100;
    letter-spacing: 3px;

    @media screen and (max-width: 414px) {
      padding: 0 20px;
    }
  }

  .service-container {
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    .service {
      height: 100px;
      text-align: left;
      position: relative;
      display: flex;
      padding: 10px;

      @media screen and (max-width: 768px) {
        margin-bottom: 20px;
      }

      p {
        font-size: .8rem;
        color: #fff;
        width: 135px;
        margin-top: 10px;
        height: 55px;
        white-space: normal;

        @media screen and (max-width: 1024px) {
          margin-top: 5px;
        }

        @media screen and (max-width: 768px) {
          width: 100%;
          text-align: center;
          line-height: 60px;
          font-size: 1rem;
        }

        @media screen and (max-width: 414px) {
          width: 100%;
          text-align: left;
          font-size: .8rem;
        }
      }

      img {
        width: 60px;
        position: absolute;
        right: 5px;
        bottom: 5px;
        opacity: .7;

        @media screen and (max-width: 1024px) {
          width: 40px;
        }
      }
    }
  }
}
</style>
