/* eslint-disable */
<template>
  <div class="footer-child-responsive">
    <div
      class="button-direction btn-left"
      @click="setDirection('prev')"
    >
      <el-button
        icon="icon-chevron-left"
        type="text"
      />
    </div>
    <div
      v-show="first"
      class="section-container"
      @click="goTo('design')"
    >
      <h1>
        DESIGN & CREATE
      </h1>
      <p>
        Whether you want a gorgeous kitchen/bathroom Countertop or
        customized stone island support, our experts are here to help.
      </p>
    </div>
    <div
      v-show="second"
      class="section-container"
      @click="goTo('repairing')"
    >
      <h1>
        REPAIRING & MAINTAINING
      </h1>
      <p>
        From repairing raptured countertop surface to fixing cracks on
        the edge of fixture or appliance supported by the countertop, we
        got you covered!
      </p>
    </div>
    <div
      v-show="third"
      class="section-container"
      @click="goTo('redesign')"
    >
      <h1>
        REPLACE & REFACE
      </h1>
      <p>
        If our stone experts determine that your countertop reached its end
        and there is no possible ways to repair, we bring back its original
        look by completely replacing it.
      </p>
    </div>
    <div
      class="button-direction btn-right"
      @click="setDirection('next')"
    >
      <el-button
        icon="icon-chevron-right"
        type="text"
      />
    </div>
  </div>
</template>

<script>
/**
*
* FooterChildResponsive Component
*
* @author cessarm_
* @copyright 2022, Eco Development Studios
*/
export default {
  name: 'FooterChildResponsive',
  data () {
    return {
      first: true,
      second: false,
      third: false
    }
  },
  methods: {
    setDirection (direction) {
      if (direction === 'prev') {
        if (this.first) {
          this.first = false
          this.third = true
        } else if (this.third === true) {
          this.third = false
          this.second = true
        } else if (this.second === true) {
          this.second = false
          this.first = true
        }
      }
      if (direction === 'next') {
        if (this.first) {
          this.first = false
          this.second = true
        } else if (this.second === true) {
          this.second = false
          this.third = true
        } else if (this.third === true) {
          this.third = false
          this.first = true
        }
      }
    },
    goTo (route) {
      this.$router.push({ name: route })
    }
  }
}
</script>

<style lang='scss'>
.footer-child-responsive {
  position: absolute;
  width: 100%;
  height: auto;
  bottom: 0;
  display: flex;
  align-items: center;
  z-index: 2;
  background-color: rgba(255, 255, 255, .1);

  .button-direction {
    height: 200px;
    line-height: 200px;

    @media screen and (max-width: 375px) {
      height: 100px;
      line-height: 140px;
    }

    i {
      font-size: 2.3rem;
      color: white;
    }
  }

  .section-container {
    height: 200px;
    width: 70%;
    margin: 0 auto;
    font-family: 'Raleway';
    font-weight: 100;
    color: white;
    padding-top: 10px;

    @media screen and (max-width: 375px) {
      height: 130px;
    }

    h1 {
      font-weight: 400;
      font-size: 1.4rem;

      @media screen and (max-width: 1024px) {
        margin-top: 40px;
      }

      @media screen and (max-width: 375px) {
        margin-top: 0;
      }
    }

    @media screen and (max-width: 375px) {
      h1 {
        font-size: 1.2rem;
      }

      p {
        font-size: .9rem;
      }
    }
  }
}
</style>
