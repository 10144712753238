<template>
  <div class="home">
    <BackgroundSlide>
      <img
        src="../assets/images/logo-index.svg"
        alt="countertop-masters"
        class="img-logo"
      >
      <FooterHome class="hidden-md-and-down" />
      <FooterHomeResponsive class="hidden-lg-and-up" />
    </BackgroundSlide>
    <AboutUs />
    <Accomplishment />
    <WeOffer />
    <TheFooter />
  </div>
</template>

<script>
import BackgroundSlide from '../components/SlideHome.vue'
import FooterHome from '../components/FooterHome.vue'
import FooterHomeResponsive from '../components/FooterChildResponsive.vue'
import AboutUs from '../components/AboutUs.vue'
import Accomplishment from '../components/Accomplishment.vue'
import WeOffer from '../components/ServiceWeOffer.vue'
import TheFooter from '../components/Footer.vue'

export default {
  name: 'HomeItem',
  components: {
    BackgroundSlide,
    FooterHome,
    AboutUs,
    Accomplishment,
    WeOffer,
    TheFooter,
    FooterHomeResponsive
  }
}
</script>

<style lang="scss">
.home {
  position: relative;
  top: 0;

  .img-logo {
    width: 250px;
    margin: 0;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    @media screen and (max-width: 768px) {
      width: 350px;
      top: 50%;
    }

    @media screen and (max-width: 414px) {
      width: 230px;
    }
  }
}
</style>
