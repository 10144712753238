<template>
  <div class="accomplishment-container">
    <div class="Accomplishment">
      <h1 class="accomplishment-title">
        OUR RECENT ACCOMPLISHMENTS
      </h1>
      <el-carousel
        :interval="5000"
        :type="setType"
        :indicator-position="showIndicators"
        height="400px"
      >
        <el-carousel-item
          v-for="img in 16"
          :key="img"
        >
          <div
            :style="{backgroundImage: 'url(' + require('../assets/images/accomplishments/'+ img + '.webp' )}"
            class="img-acco"
            @click="openModalImg(img)"
          />
        </el-carousel-item>
      </el-carousel>
      <el-dialog
        style="z-index: 2002 !important;"
        :visible.sync="dialogVisible"
        :width="setSize"
        @close="erasePhoto"
      >
        <img
          class="img-accomplishment"
          :src=" require('../assets/images/accomplishments/HD/'+ background + '.webp')"
          alt=""
        >
      </el-dialog>
    </div>
  </div>
</template>

<script>
/**
*
* Accomplishment Component
*
* @author cessarm_
* @copyright 2022, Eco Development Studios
*/
export default {
  name: 'AccomplishmentItem',
  data () {
    return {
      window: {
        width: 0,
        height: 0
      },
      dialogVisible: false,
      background: '0',
      type: 'card',
      images: [
        {
          name: 'acc-06.webp'
        },
        {
          name: 'acc-07.webp'
        },
        {
          name: 'acc-08.webp'
        },
        {
          name: 'acc-09.webp'
        },
        {
          name: 'acc-10.webp'
        }
      ]
    }
  },
  computed: {
    setType () {
      if (this.window.width <= 414) {
        return ''
      } else {
        return 'card'
      }
    },
    showIndicators () {
      if (this.window.width <= 414) {
        return 'none'
      } else {
        return 'outside'
      }
    },
    setSize () {
      if (this.window.width <= 414) {
        return '100%'
      } else {
        return '50%'
      }
    }
  },
  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },
    openModalImg (name) {
      this.background = name
      this.dialogVisible = true
    },
    erasePhoto () {
      this.background = '0'
    }
  }
}
</script>

<style lang='scss'>
.accomplishment-container {
  width: 100%;
}

.Accomplishment {
  width: 1280px;
  margin: 0 auto;

  @media screen and (max-width: 1280px) {
    width: 100%;
  }

  .accomplishment-title {
    font-family: 'Raleway', sans-serif;
    font-weight: 100;
    letter-spacing: 3px;

    @media screen and (max-width: 414px) {
      font-size: 1.6rem;
    }
  }

  .img-acco {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center right;

    @media screen and (max-width: 414px) {}
  }

  .img-accomplishment {
    width: 100%;
    height: 600px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center right;
  }

  .el-dialog__header,
  .el-dialog__body {
    padding: 0 !important;
  }

  .el-dialog {
    overflow-y: hidden !important;
    margin-top: 5vh !important;
  }
}
</style>
